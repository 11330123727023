<!--
File: TestRMTreatments.vue
Description: shows list of treatments for editing.
-->
<template>
  <base-data-table v-if="eligible" :loading="showSpinner" :pagination="pagination"
    :btnAddAllowed="isBtnAllowed('AddButton')" :from="from" :to="to" :total="total" @add-item="viewItem()">

    <template #header-filters>
      <div class="md-layout-item md-small-size-100 md-size-15">
        <UnitsDropdown v-model="selectedUnit" />
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <search-input v-model="searchQuery" />
      </div>
    </template>

    <template #table-rows>
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item[idFieldName] === highlightedRow }"
          :id="item[idFieldName] === highlightedRow ? 'highlightedRow' : null">

          <md-table-cell v-for="header in headers" :key="header.value" :md-label="$t(header.text)"
            :md-sort-by="header.sortable ? header.value : null" :md-numeric="header.format === 'number'">
            {{ item[header.value] }}
          </md-table-cell>

          <md-table-cell :md-label="$t('tables.actions')">
            <table-actions :btnEditAllowed="isBtnAllowed('EditButton')" :btnDeleteAllowed="isBtnAllowed('DeleteButton')"
              @edit="viewItem(item[idFieldName])" @delete="deleteItem(item[idFieldName], item.description)" />
          </md-table-cell>
        </md-table-row>
      </md-table>
    </template>

    <template #editing-dialog>
      <EditForm v-if="showDetailsDlg" :itemId="currentId" @close="onEditFormClose" />
    </template>
  </base-data-table>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { UnitsDropdown } from '@/pages/Components'
  import BaseDataTable from "@/pages/Components/BaseDataTable.vue"
  import BaseTableMixin from '@/mixins/BaseTableMixin'
  import SearchInput from "@/pages/Components/SearchInput.vue"
  import TableActions from "@/pages/Components/TableActions.vue"
  import messagesMixin from '@/mixins/messagesMixin'
  import EditForm from './TestRMTreatmentEditForm.vue'

  export default {
    name: 'test-rm-treatments-list',
    mixins: [BaseTableMixin, messagesMixin],

    components: {
      UnitsDropdown,
      BaseDataTable,
      SearchInput,
      TableActions,
      EditForm
    },

    data() {
      return {
        // TODO: change to appropriate name and add to the permissions table
        formName: 'Deu',
        idFieldName: 'id',
        propsToSearch: ['description', 'units'],
        currentSort: 'year',

        selectedUnit: null,

        historyMapping: {
          selectedUnit: 0,
          currentSort: 'description',
          currentSortOrder: 'asc',
          pagination: this.pagination,
          searchQuery: ''
        }
      }
    },

    async mounted() {
      console.log('tableData', this.tableData)
    },

    methods: {
      ...mapActions({
        clearList: 'TestRM/CLEAR_RM_TREATMENT_LIST',
        loadList: 'TestRM/LOAD_RM_TREATMENT_LIST',
        delete: 'TestRM/DELETE_RM_TREATMENT',
      }),

      toggleSpinner(state) {
        this.showSpinner = state
      },

      onEditFormClose() {
        this.showDetailsDlg = false;
        this.loadList(this.payload);
        //console.log(JSON.stringify(this.tableData))
      },
    },

    computed: {
      ...mapState({
        tableData: (state) => state.TestRM.rm_treatment_list,
      }),

      headers() {
        return [
          { text: "id", value: "id", sortable: true },
          //{ text: "treatment.treatment_key", value: "treatment_key", sortable: true },
          { text: "stdCols.description", value: "description", sortable: true },
          { text: "label.units", value: "units", sortable: true },
          { text: "tables.cost", value: "cost", sortable: true },

        ]
      }
    },

    watch: {
      selectedUnit(value) {
        this.payload.fk_unit = value;
        this.loadList(this.payload);
      },
    }
  }
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';
</style>