<!--
File: TestRMTreatmentEditForm.vue
Description: form for adding/editing a single RM Treatment.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <div class="md-layout">
        <!--TODO: treatment.treatment_key-->
        <div class="md-layout-item md-size-100">
          <md-field :class="getClass('description')">
            <label :for="description">{{ $t('stdCols.description') }}</label>
            <md-input id="description" type="text" v-model="description" data-vv-name="description"
              v-validate="modelValidations.description" required />
          </md-field>
        </div>
        <div class="md-layout-item md-size-50">
          <UnitsDropdown :class="getClass('fk_unit')" v-model="fk_unit" data-vv-name="fk_unit"
            v-validate="modelValidations.fk_unit" @input="onUnitChange" required />
        </div>
        <div class="md-layout-item md-size-40">
          <FloatInput :class="getClass('cost')" :label="$t('tables.cost')" v-model="cost" data-vv-name="cost"
            v-validate="modelValidations.cost" :step="0.1" required />
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import { mapActions } from 'vuex'
import { Modal, UnitsDropdown, FloatInput } from '@/pages/Components'
import { savedMessage } from '@/mixins/messagesMixin'
import permissions from "@/mixins/permissionsMixin"

export default {
  name: 'rm-treatment-edit-form',
  mixins: [permissions],

  data() {
    return {
      // TODO: change to appropriate name and add to the permissions table
      formName: 'DeuEditForm',
      eligible: false,

      description: null,
      fk_unit: null,
      cost: null,

      // TODO: remove 1 line below after API implmenetation
      units: null,

      initialFormState: null,
      modelValidations: {
        description: { required: true, min: 3 },
        fk_unit: { required: true, numeric: true, min_value: 1 },
        cost: { required: true, decimal: true, min_value: 0.1 },
      }
    }
  },

  props: {
    itemId: null
  },

  components: {
    Modal,
    UnitsDropdown,
    FloatInput
  },

  async mounted() {
    // Check if we are eligible to view the form
    this.eligible = await this.checkIfScreenAllowed()
    if (!this.eligible) {
      this.$emit('close');
      return
    };

    if (this.itemId) {
      const theItem = await this.loadItem(this.itemId)
      this.description = theItem.description
      this.fk_unit = theItem.fk_unit
      this.cost = theItem.cost
    }
    this.$nextTick(async () => {
      this.initialFormState = this.getCurrentState;
      await this.$validator.validateAll();
    });
  },

  methods: {
    ...mapActions({
      loadItem: 'TestRM/LOAD_RM_TREATMENT_BY_ID',
      addItem: 'TestRM/ADD_NEW_RM_TREATMENT',
      editItem: 'TestRM/UPDATE_RM_TREATMENT',
      highlightRow: 'HIGHLIGHT_ROW'
    }),

    // TODO: remove this method after API implmentation, as well as in the template section
    onUnitChange(id, desc) {
      this.units = desc
    },

    async validate() {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return

      const theItem = this.getCurrentState;
      // TODO: remove 1 line below after API implmenetation
      theItem.units = this.units

      let errDesc = '';
      let newId;
      const action = !this.itemId ? this.addItem : this.editItem;
      const payload = !this.itemId ? theItem : { id: this.itemId, theItem };
      try {
        const res = await action(payload)
        newId = res?.id;
      } catch (err) {
        errDesc = err.message || this.$t('messages.unknown_error')
      }

      this.$nextTick(() => this.$validator.reset())
      this.$emit('close');
      await savedMessage(errDesc, this.$t('label.units'), this.description)
      this.highlightRow(newId)
    },
  },

  computed: {
    screenTitle() {
      return this.itemId ? this.$t('screen_titles.treatment_type_upd') : this.$t('screen_titles.treatment_type_add')
    },

    getCurrentState() {
      return {
        description: this.description,
        fk_unit: this.fk_unit,
        cost: this.cost,
      }
    },

    hasUnsavedChanges() {
      if (!this.initialFormState) return false

      return this.description !== this.initialFormState.description ||
        this.fk_unit !== this.initialFormState.fk_unit ||
        this.cost !== this.initialFormState.cost
    }
  }
}
</script>